import { firstValueFrom, catchError } from 'rxjs'
import type { AxiosResponse, AxiosError } from 'axios'
import { BaseRepository } from './abstract.repo'
import { TokenEntity } from '../entities/TokenEntity'
import { UserEntity } from '../entities/UserEntity'

class AuthRepository extends BaseRepository {
  private static readonly login_url = 'auth/login'
  private static readonly get_info_url = 'auth/user/info'

  login(username: string, password: string): Promise<AxiosResponse<TokenEntity>> {
    return firstValueFrom(
      this.post(AuthRepository.login_url, { email: username, password: password }).pipe(
        catchError((error: AxiosError) => {
          throw error
        }),
      ),
    )
  }

  getInfo(): Promise<AxiosResponse<UserEntity>> {
    return firstValueFrom(
      this.get(AuthRepository.get_info_url).pipe(
        catchError((error: AxiosError) => {
          throw error
        }),
      ),
    )
  }
}

export default AuthRepository
