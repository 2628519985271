import type { ComponentPublicInstance } from 'vue'
import { BadRequestException, UnauthorizedException } from '../../exceptions/http'
import { unauthorizedHandler } from './unauthorized.hander'
import { badRequestHandler } from './bad-request.hander'

/**
 * Simple handlers modules to handle kinds of Error and Exception
 */
const handlers: Map<string, Handler> = new Map()

export type Handler = { (err: any, instance: ComponentPublicInstance | null, info: string): void }

export function registerHandler(error: string, func: Handler) {
  handlers.set(error, func)
}

export function removeHandler(error: string) {
  handlers.delete(error)
}

/**
 * Register Handlers
 */
registerHandler(UnauthorizedException.name, unauthorizedHandler)
registerHandler(BadRequestException.name, badRequestHandler)

export const handler: Handler = function (error, instance, info): void {
  console.error('Lỗi toàn cục:', error, instance, info)
  handlers.forEach((handler, key) => {
    if (error.name === key) {
      /**
       * First handler match will be executed and then we stop
       */
      return handler(error, instance, info)
    }
  })
  /**
   * Default handling is logging to console
   */
  console.log('default handle ' + error)
}
