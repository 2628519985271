import { defineStore } from 'pinia'
import AuthRepo from '../repositories/auth.repo'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      access_token: JSON.parse(localStorage.getItem('access_token') as string),
      // refresh_token: JSON.parse(localStorage.getItem('refresh_token') as string),
      userInfo: JSON.parse(localStorage.getItem('user_info') as string),
      refresh_token: null,
      returnUrl: '/dashboard',
    }
  },

  actions: {
    getToken() {
      return this.access_token
    },
    isAuthenticated() {
      return this.access_token != null
    },
    async getUserInfo() {
      return this.userInfo
    },
    checkOAuth(token: string) {
      console.log(token)
    },
    async login(username: string, password: string) {
      const authRepo = new AuthRepo()
      const tokenEntity = await authRepo.login(username, password)
      this.access_token = tokenEntity.access_token
      // this.refresh_token = tokenEntity.refresh_token || ''
      localStorage.setItem('access_token', JSON.stringify(tokenEntity.access_token))
      // localStorage.setItem('refresh_token', JSON.stringify(tokenEntity.refresh_token))
      const userInfo = await authRepo.getInfo()
      localStorage.setItem('user_info', JSON.stringify(userInfo))
    },
    logout() {
      this.access_token = null
      localStorage.removeItem('access_token')

      // this.refresh_token = null
      // localStorage.removeItem('refresh_token')

      this.userInfo = null
      localStorage.removeItem('user_info')
    },
  },
})
