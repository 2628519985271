import { UnauthorizedException } from '../../exceptions/http'
import { useUserStore } from '../../stores/user.store'

export function unauthorizedHandler(error: UnauthorizedException) {
  /**
   * UnAuthorizedException has been catch and handled by Axios but we can further do something here like logout
   */
  console.error(error)
  const userStore = useUserStore()
  return userStore.logout()
}
