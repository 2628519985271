{
  "design-3d": {
    "listing": {
      "title": "Danh sách"
    }
  },
  "auth": {
    "agree": "Đồng ý",
    "createAccount": "Tạo tài khoản",
    "createNewAccount": "Tạo tài khoản mới",
    "email": "Email",
    "login": "Đăng nhập",
    "password": "Mật khẩu",
    "recover_password": "Lấy lại mật khẩu",
    "sign_up": "Đăng ký",
    "keep_logged_in": "Giữ đăng nhập",
    "termsOfUse": "Điều khoản",
    "reset_password": "Cài lại mật khẩu"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Trở lại"
  },
  "400": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Trở lại"
  },
  "typography": {
    "primary": "Primary text styles",
    "secondary": "Secondary text styles"
  },
  "dashboard": {
    "versions": "Versions",
    "setupRemoteConnections": "Setup Remote Connections",
    "currentVisitors": "Current Visitors",
    "navigationLayout": "navigation layout",
    "topBarButton": "Top Bar",
    "sideBarButton": "Side Bar"
  },
  "language": {
    "english": "Tiếng Anh",
    "vietnamese": "Tiếng Việt"
  },
  "menu": {
    "auth": "Auth",
    "buttons": "Buttons",
    "timelines": "Timelines",
    "dashboard": "Dashboard",
    "billing": "Thanh toán",
    "login": "Đăng nhập",
    "preferences": "Thông tin tài khoản",
    "payments": "Thanh toán",
    "settings": "Cài đặt",
    "pricing-plans": "Pricing plans",
    "payment-methods": "Payment methods",
    "signup": "Đăng ký",
    "recover-password": "Quên mật khẩu",
    "recover-password-email": "Recover password email",
    "404": "404",
    "faq": "FAQ",
    "users": "Người dùng",
    "projects": "Projects"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read"
  },
  "navbar": {
    "messageUs": "Web development inquiries:",
    "repository": "GitHub Repo"
  },
  "notifications": {
    "all": "Xem toàn bộ thông báo",
    "less": "See less notifications",
    "mark_as_read": "Mark as read",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "user": {
    "language": "Thay đổi ngôn ngữ",
    "logout": "Logout",
    "profile": "Profile",
    "settings": "Settings",
    "billing": "Billing",
    "faq": "FAQ",
    "helpAndSupport": "Help & support",
    "projects": "Projects",
    "account": "Account",
    "explore": "Explore"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Send"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs alignment",
    "overflow": "Tabs overflow",
    "hidden": "Tabs with hidden slider",
    "grow": "Tabs grow"
  },
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Vuestic Admin",
  "search": {
    "placeholder": "Tìm kiếm..."
  },
  "buttonSelect": {
    "dark": "Tối",
    "light": "Sáng"
  }
}
